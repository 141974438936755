import Features from "@components/Features";
import FrequentlyAskedQuestions from "@components/FrequentlyAskedQuestions";
import Plans from "@components/Plans";
import SubscriptionForm from "@components/SubscriptionForm";
import ErrorPage from "@pages/ErrorPage";
import LoadingPage from "@pages/LoadingPage";
import { Suspense, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Await, useLoaderData } from "react-router-dom";
import {
  GetPricesData,
  GetPricesLoaderResult,
} from "src/loaders/getPricesLoader";

export default function PlansPage() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const data = useLoaderData() as GetPricesLoaderResult;
  const { t } = useTranslation();

  if (isSubmitting) return <LoadingPage />;

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.getPricesData}>
        {(getPricesData: GetPricesData) => (
          <>
            <div className="custom-container pb-14 pt-8">
              <h1 className="custom-heading mb-14">
                <Trans
                  components={{
                    colored: <span className="custom-text-colored" />,
                  }}
                  i18nKey="pages.checkout.plans.heading"
                />
              </h1>

              <section className="mb-16">
                <Plans
                  eligibleForIntroPrice={
                    getPricesData?.subscriptionStatus === "none"
                  }
                  currency={getPricesData.currency}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  weeklyPrice={getPricesData.weeklyPrice}
                  weeklyIntroPrice={getPricesData.weeklyIntroPrice}
                />
              </section>

              <section className="mb-12">
                <Features />
              </section>

              <section className="mb-24">
                <FrequentlyAskedQuestions />
              </section>

              <section className="space-y-4 text-center">
                <SubscriptionForm
                  buttonTitle={t("shared.continue")}
                  currency={getPricesData.currency}
                  isSubmitting={isSubmitting}
                  plan="weekly"
                  priceId={
                    getPricesData?.subscriptionStatus === "none"
                      ? getPricesData?.weeklyIntroPrice?.id
                      : getPricesData?.weeklyPrice?.id
                  }
                  setIsSubmitting={setIsSubmitting}
                />

                <p className="font-bold">
                  {t("pages.checkout.plans.cancelAnytime")}
                </p>
              </section>
            </div>
          </>
        )}
      </Await>
    </Suspense>
  );
}
