import { redirect } from "react-router-dom";

const ORDERED_DEFAULT_ONBOARDING_ROUTES = [
  "age",
  "company",
  "gender",
  "topics",
  "learn",
  "learn-faster",
  "productivity",
  "learning",
  "tailored",
  "opportunities",
  "potential",
  "complex",
  "progress",
  "youtube-or-podcasts",
  "youtube-covered",
];

export default async function navigateToNextOnboardingPageAction({
  request,
}: {
  request: Request;
}) {
  const formData = await request.formData();

  // NOTE: Get form data entries:
  // console.log("formData", Object.fromEntries(formData));

  // TODO: Multiple checkbox values are not being captured correctly
  // const topics = formData.getAll("topics");
  // console.log("topics", topics);

  const next = getNext(request.url);

  const currentPage = request.url.split("/").pop()?.split("?")[0];

  if (!next) return redirect("/sign-up/password");

  const nextPathname = getNextPathName({ next, url: request.url });

  if (currentPage === "success") {
    const hash = formData.get("success") as string;

    return redirect(`${nextPathname}#${hash}`);
  } else {
    return redirect(nextPathname);
  }
}

function getNext(url: string): string | undefined {
  const pathname = new URL(url).pathname;
  const current = pathname.split("/").pop();

  if (!current) return;

  const orderedOnboardingRoutes = getOrderedOnboardingRoutes();
  const currentIndex = orderedOnboardingRoutes.indexOf(current);
  const next = orderedOnboardingRoutes[currentIndex + 1];

  return next;
}

function getNextPathName({ next, url }: { next: string; url: string }): string {
  const currentPathname = new URL(url).pathname;
  const paths = currentPathname.split("/");
  const basePathname = paths.slice(0, -1).join("/");
  const nextPathname = basePathname + "/" + next;
  return nextPathname;
}

function getOrderedOnboardingRoutes(): string[] {
  return ORDERED_DEFAULT_ONBOARDING_ROUTES;
}
