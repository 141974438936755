import PlanCard from "@components/PlanCard";
import { StripePrice } from "src/types";

interface Props {
  currency: string;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  eligibleForIntroPrice: boolean;
  weeklyIntroPrice: StripePrice | undefined;
  weeklyPrice: StripePrice | undefined;
}

export default function Plans({
  eligibleForIntroPrice,
  currency,
  isSubmitting,
  setIsSubmitting,
  weeklyIntroPrice,
  weeklyPrice,
}: Props) {
  return (
    <div className="md:px-20">
      <PlanCard
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={1}
        periodUnit="week"
        plan="weekly"
        setIsSubmitting={setIsSubmitting}
        eligibleForIntroPrice={eligibleForIntroPrice}
        stripeIntroPrice={weeklyIntroPrice}
        stripePrice={weeklyPrice}
      />
    </div>
  );
}
