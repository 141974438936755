import getSubscriptionStatus from "@helpers/getSubscriptionStatus";
import { getUserCurrency } from "@helpers/sessionStorage";
import { getSession, listStripePrices } from "@helpers/supabase";
import { defer, redirect } from "react-router-dom";
import { StripePrice } from "src/types";

export interface GetPricesLoaderResult {
  getPricesData: GetPricesData;
}

export interface GetPricesData {
  currency: string;
  subscriptionStatus: "active" | "inactive" | "none";
  weeklyPrice: StripePrice | undefined;
  weeklyIntroPrice: StripePrice | undefined;
  yearlyPrice: StripePrice | undefined;
  yearlyIntroPrice: StripePrice | undefined;
}

export default function getPricesLoader() {
  const getPricesPromise = getPrices();

  return defer({
    getPricesData: getPricesPromise,
  });
}

async function getPrices(): Promise<GetPricesData | Response> {
  const [{ data }, stripePrices] = await Promise.all([
    getSession(),
    listStripePrices(),
  ]);

  const currency = await findCurrency({ stripePrices });

  const userId = data?.session?.user?.id;

  if (!userId) {
    return redirect("/sign-up");
  }

  const subscriptionStatus = await getSubscriptionStatus(userId);

  return {
    currency: currency,
    subscriptionStatus: subscriptionStatus,
    weeklyPrice: stripePrices.find((price) => price.lookup_key === "w-full"),
    weeklyIntroPrice: stripePrices.find(
      (price) => price.lookup_key === "w-intro",
    ),
    yearlyPrice: stripePrices.find((price) => price.lookup_key === "y-full"),
    yearlyIntroPrice: stripePrices.find(
      (price) => price.lookup_key === "y-intro",
    ),
  };
}

async function findCurrency({
  stripePrices,
}: {
  stripePrices: StripePrice[];
}): Promise<string> {
  const userCurrency = (await getUserCurrency()).toLowerCase();

  const stripePriceCurrencies = stripePrices.flatMap((price) => [
    price.currency,
    ...Object.keys(price?.currency_options),
  ]);

  return stripePriceCurrencies.includes(userCurrency) ? userCurrency : "eur";
}
