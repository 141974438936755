import mindMapBackgroundUrl from "@assets/mind_map_background.webp";
import { ReactNode } from "react";

export default function LayoutWithBackgroundImage({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div
      className="h-dvh bg-custom-black"
      style={{
        backgroundImage: `url(${mindMapBackgroundUrl}`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <main className="custom-container h-dvh pb-14 pt-8 text-white">
        {children}
      </main>
    </div>
  );
}
